/* Hamburger.css */
.hamburger {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .line {
    width: 100%;
    height: 4px;
    background-color: #333;
    border-radius: 4px;
    transition: transform 0.3s ease;
  }
  
  .open .line:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }
  
  .open .line:nth-child(2) {
    opacity: 0;
  }
  
  .open .line:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }
  