/* Navbar.css */
.navbar {
    background: linear-gradient(to right, white, #1c2e62);
    color: #fff;
    padding: 15px 0;
    border-bottom: 1px solid #1c2f64;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.logo {
    margin: 0;
    width: 8vw;
}

.nav-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.nav-item {
    margin: 0 10px;
}

.nav-item a {
    color: #fff;
    text-decoration: none;
}
.show-hamburger{
    display: none;
}

/* Media Query for responsiveness */
@media screen and (max-width: 768px) {
    .show-hamburger{
        display: flex;
    }
    .logo{
        width: 26vw;
    }
    .nav-menu {
        display: none;
        flex-direction: column;
        text-align: center;
    }

    .nav-item {
        margin: 10px 0;
    }

    .nav-item a {
        padding: 10px;
        display: block;
    }
}