.call-icon{
    position: fixed;
    font-size: 6vw;
    top: 72vh;
    left: 84vw;
}

.call-icon img{
    width: 82px;
}

@media screen and (max-width:784px) {
    .call-icon{
        top: 77vh;
        left: 70vw;    
    }
}