/* Carousel.css */
.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 12px;
    background: #2a2a2d;
  }
  
  .carousel-items {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    flex: 1 0 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel-item img{
    border: 3px solid #1b2f61;
    border-radius: 3px;
  }
  
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
  }
  
  .prev-btn {
    left: 0;
  }
  
  .next-btn {
    right: 0;
  }

  @media screen and (max-width: 768px) {
    .carousel-item img{
        height: 236px;
    }
  }
  