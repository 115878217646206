/* Footer.css */
.footer {
    background-color: #333;
    color: #fff;
    /* padding: 30px 0; */
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content {
    flex: 1;
  }

  .footer-content a{
    color: white;
    text-decoration: none;
  }
  
  .footer-content h3 {
    margin-bottom: 10px;
  }
  
  .social-links {
    list-style: none;
    padding: 0;
  }
  
  .social-links li {
    margin-bottom: 5px;
  }
  
  .social-links li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-bottom {
    background-color: #222;
    text-align: center;
    padding: 10px 0;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
@media screen and (max-width: 768px) {
  .footer-container{
    margin: 0 23px;
  }
}
  