.top_container {
  background: radial-gradient(#606b87, #d5a7ac);
}

.card_view {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 25px;
}

.title_ongoing_projects {
  padding-left: 9vw;
  display: flex;
  flex-direction: column;
  background: #fdfdfd;
}

.title_ongoing_projects h1 {
  color: #1a305f;
  margin: 21px 0 0 0;
}

.title_ongoing_projects .underline {
  width: 153px;
  height: 6px;
  background: #cf1f25;
}

.contact-page .maps {
  background: #2d2e2f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 20px;
}

.contact-page .maps img {
  width: 37vw;
}

.contact-page .maps p {
  width: 28vw;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-page .maps p a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}


@media screen and (max-width: 768px) {
  .title_ongoing_projects {
    font-size: 15px;
    margin-top: 24px;
  }

  .contact-page .maps {
    flex-direction: column;
  }

  .contact-page .maps img {
    width: 96vw;
  }

  .contact-page .maps div{
    width: 100%;
  }
}