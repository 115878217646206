/* AboutPage.css */
.about-page {
    display: flex;
    flex-direction: column;
    /* background: no-repeat center/80% url("../Images/I_Rise_Logo-removebg.png"); */
  }
  
  .about-content {
    line-height: 2;
    text-align: center;
    padding: 20px;
    font-size: 20px;
  }

  .about-content div ul{
    list-style: circle;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-weight: 700;
    color: #1b2f61;
  }
  
  .about-image {
    margin-top: 20px;
  }

  .about-image h1{
    color: red;
    font-size: 56px;
    padding: 2px;
    background: beige;
    border-radius: 20px;
  }
  
  .about-image img {
    border-radius: 0px 0px 136px 136px;
    max-width: 100%;
    height: auto;
  }

  .features{
    display: flex;
    background: linear-gradient(45deg, #51a1c8, transparent);
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 25px;

  }

  .features div h4{
    margin: 0;
  }

  .features div p{
    background: #212121;
    color: white;
    padding: 15px;
    font-size: 17px;
    margin: 0px 6px;
    border-radius: 7px;
  }

  .ownerInfo div img{
    width: 120px;
    border-radius: 50px;
  }

  .ownerInfo div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .ownerInfo div p{
    background: #74b4d3;
    color: white;
    padding: 6px;
    border-radius: 12px;
  }

  .ownerInfo{
    margin-left: 2vw;
    display: flex;
    justify-content: space-around;
  }


  
  /* Media Query for responsiveness */
  @media screen and (max-width: 768px) {
    /* .about-page {
      flex-direction: row;
    } */
  
    .about-content {
      flex: 1;
      padding: 0 20px;
    }
  
    .about-image {
      margin-top: 0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .features{
      flex-direction: column;
    }

    .about-image h1{
      font-size: 24px;
    }

    .ownerInfo{
      flex-direction: column;
    }
  }
  