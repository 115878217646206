.vijaydurg .main-photo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-photo img{
    width: 68vw;
}

.vijaydurg .para-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: serif;
}

.vijaydurg .para{
    margin: 20px 108px;
    font-size: 21px;
    font-family: serif;
    display: flex;
    flex-direction: column;
    align-items: end;
}

@media screen and (max-width:784px) {
    .main-photo img{
        width: 96vw;
    }

    .vijaydurg .para{
        margin: 20px 11px;
    }

    .vijaydurg .para iframe{
        width: 96vw;
    }
}